import React, { FC, ReactElement } from "react"

import { IPropsTabTitle } from "./model"

const TabTitle: FC<IPropsTabTitle> = (props): ReactElement | null => {
  const { title, classes } = props

  return (
    <h3 data-test="TabTitle" className={classes}>
      {title}
    </h3>
  )
}

export default TabTitle
