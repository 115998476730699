import React, { FC, ReactElement } from "react"

import { IPropsWhyNurofenTitle } from "./model"

const WhyNurofenTitle: FC<IPropsWhyNurofenTitle> = (
  props
): ReactElement | null => {
  const { title, classes } = props

  return (
    <h2 data-test="WhyNurofenTitle" className={classes}>
      {title}
    </h2>
  )
}

export default WhyNurofenTitle
